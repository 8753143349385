module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-modal-routing-3/gatsby-browser.js'),
      options: {"plugins":[],"appElement":"#___gatsby","modalProps":{"style":{"overlay":{"position":"fixed","top":0,"left":0,"right":0,"bottom":0,"backgroundColor":"rgba(255, 255, 255, 0.9)"},"content":{"position":"absolute","top":"0","left":"0","right":"0","bottom":"0","border":"none","background":"none","overflow":"auto","WebkitOverflowScrolling":"touch","borderRadius":"0px","outline":"none","padding":"0px"}},"preventScroll":true,"htmlOpenClassName":"ReactModal__Html--open"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/NED_FAVICON.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"04bb884de2aaba630c79b4e8c3f7f290"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
